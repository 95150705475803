import React from 'react';
import styled, { css } from 'styled-components';

import { PRIMARY } from 'src/common/colors';
import { TABLET_WIDTH } from 'src/common/constants';

const Main = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PRIMARY};
  color: white;
  font-size: 16px;
  border-radius: 2px;
  transition: color 150ms ease-in-out, border 150ms ease-in-out,
    background 150ms ease-in-out;
  cursor: pointer;

  ${props =>
    props.small
      ? css`
          height: 36px;
          width: 110px;
        `
      : css`
          height: 48px;
          width: 150px;
        `};

  ${({ mode }) =>
    mode === 'light' &&
    css`
      background-color: unset;
      border: 1px solid #ffffff;

      &:hover {
        color: #c7cdd6;
        border: 1px solid #c7cdd6;
      }
    `};

  @media (max-width: ${TABLET_WIDTH}px) {
    height: 36px;
    width: 110px;
  }
`;

const Button = ({ children, ...rest }) => {
  return <Main {...rest}>{children}</Main>;
};

export default Button;
