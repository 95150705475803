import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Footer from './Footer';
import Header from './Header';

import 'src/common/styles.css';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Layout = ({ children }) => (
  <Main>
    <Helmet
      title="Shroombox"
      meta={[
        {
          name: 'description',
          content: 'High-quality Mushroom Growkits',
        },
        {
          name: 'keywords',
          content: 'grow, kit, growery, mushroom, indoor',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <Header />
    {children}
    <Footer />
  </Main>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
