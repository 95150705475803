import React from 'react';
import styled from 'styled-components';
import EmailIcon from '@material-ui/icons/Email';
import PhoneLinkIcon from '@material-ui/icons/PhonelinkRing';

import { TABLET_WIDTH } from 'src/common/constants';
import Hero from 'src/common/components/Hero';
import Layout from 'src/common/components/Layout';
import Section from 'src/common/components/Section';

const RowHolder = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${TABLET_WIDTH}px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0 10px;

  & > svg {
    margin-right: 10px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    font-size: 18px;

    & > svg {
      margin-right: 20px;
    }
  }
`;

const Contact = () => (
  <Layout>
    <Section style={{ flex: 1 }}>
      <Hero
        title="Contact Us"
        renderContent={() => (
          <RowHolder>
            <IconRow>
              <PhoneLinkIcon />
              (970) 315-4743
            </IconRow>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <IconRow>
              <EmailIcon />
              info@shroombox.com
            </IconRow>
          </RowHolder>
        )}
      />
    </Section>
  </Layout>
);

export default Contact;
