import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { PRIMARY } from 'src/common/colors';
import { MOBILE_WIDTH } from 'src/common/constants';

import Logo from './Logo';
import Nav from './Nav';

const HEADER_HEIGHT = 115;

// Update to grid for dynamic resizing
const Main = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  background-color: ${PRIMARY};
  padding: 0 50px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 0 20px;
  }
`;

const Header = () => (
  <Main>
    <Link
      to="/"
      style={{
        color: 'white',
        textDecoration: 'none',
      }}
    >
      <Logo />
    </Link>
    <Nav />
  </Main>
);

export default Header;
