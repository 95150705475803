import React from 'react';
import styled from 'styled-components';

const Main = styled.h2`
  color: white;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin: 0;
`;

const Logo = () => <Main>Shroombox</Main>;

export default Logo;
