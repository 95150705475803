import React from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH, TABLET_WIDTH } from 'src/common/constants';

const Main = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;

  @media (max-width: 700px) {
    font-size: 1rem;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 90%;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  white-space: nowrap;

  @media (max-width: ${TABLET_WIDTH}px) {
    font-size: 2rem;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    font-size: 1.8rem;
  }
`;

const Content = styled.h2`
  font-weight: 300;
  text-align: center;

  @media (max-width: ${TABLET_WIDTH}px) {
    font-size: 1rem;
  }
`;

const HeroContent = props => {
  if (props.renderContent) {
    return props.renderContent();
  }

  return <Content>{props.content}</Content>;
};

const Hero = props => (
  <Main>
    <Title>{props.title}</Title>
    <HeroContent {...props} />
  </Main>
);

export default Hero;
