import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class LocalMenu extends React.Component {
  handleItemClick = onClick => {
    this.props.handleClose();
    onClick();
  };

  renderMenuItems = () => {
    if (this.props.items) {
      return this.props.items.map(item => (
        <MenuItem
          key={item.key}
          onClick={() => this.handleItemClick(item.onClick)}
        >
          {item.label}
        </MenuItem>
      ));
    }

    return this.props.children;
  };

  render() {
    const { anchorEl, handleClose } = this.props;

    return (
      <Menu
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {this.renderMenuItems()}
      </Menu>
    );
  }
}

export default LocalMenu;
