import React from 'react';
import styled, { css } from 'styled-components';

import { PRIMARY, LIGHT_ACCENT } from 'src/common/colors';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'src/common/constants';

const MODES = {
  dark: PRIMARY,
  light: LIGHT_ACCENT,
  white: 'white',
};

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 50px;
  box-sizing: border-box;
  overflow: hidden;

  ${({ mode = 'white' }) => css`
    background-color: ${MODES[mode]};
  `};

  ${({ mode }) =>
    mode === 'dark'
      ? css`
          color: white;
        `
      : css`
          color: ${PRIMARY};
        `};

  ${({ border }) =>
    border &&
    css`
      border-bottom: 1px solid #edf1f5;
    `};

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 30px 50px;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 30px 20px;
  }
`;

const Section = ({ border, children, mode, style = {} }) => (
  <Main border={border} mode={mode} style={style}>
    {children}
  </Main>
);

export default Section;
