import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Button from 'src/common/components/base/Button';
import { PRIMARY } from 'src/common/colors';
import { MOBILE_WIDTH } from 'src/common/constants';

const FOOTER_HEIGHT = 170;

const Main = styled.div`
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${PRIMARY};
  padding: 0 50px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 0 20px;
  }
`;

const Copyright = styled.span`
  font-size: 14px;
  color: #6f7885;
`;

const Footer = () => (
  <Main>
    <div>
      <Copyright>SHROOMBOX&trade; 2019</Copyright>
    </div>
    <div>
      <Button
        onClick={() => navigate('/contact')}
        role="link"
        tabIndex="0"
        mode="light"
      >
        Contact Us
      </Button>
    </div>
  </Main>
);

export default Footer;
