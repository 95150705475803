import React from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';

import LocalMenu from 'src/common/components/base/LocalMenu';

const NAV_ITEMS = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Contact',
    link: '/contact/',
  },
];

const StyledMenu = styled.div`
  & a {
    padding: 0.75rem;
    color: white;
    font-weight: 300;
    transition: color ease-in-out 150ms;
    cursor: pointer;
  }

  & a:visited {
    color: white;
  }

  & a:hover {
    color: #c7cdd6;
  }
`;

const MainMenu = styled(StyledMenu)`
  @media (max-width: 700px) {
    display: none;
  }
`;

const MiniMenu = styled(StyledMenu)`
  display: none;
  color: white;
  cursor: pointer;
  padding: 5px;

  & > svg {
    font-size: 30px;
  }

  @media (max-width: 700px) {
    display: block;
  }
`;

class Nav extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenuClick = e => this.setState({ anchorEl: e.currentTarget });

  handleMenuClose = () => this.setState({ anchorEl: null });

  createMenuItems = () =>
    NAV_ITEMS.map(({ link, title }) => ({
      key: link,
      label: title,
      onClick: () => navigate(link),
    }));

  renderItems = () => (
    <React.Fragment>
      {NAV_ITEMS.map(({ link, title }) => (
        <Link key={link} to={link}>
          {title}
        </Link>
      ))}
    </React.Fragment>
  );

  render() {
    return (
      <React.Fragment>
        <MainMenu>{this.renderItems()}</MainMenu>
        <MiniMenu onClick={this.handleMenuClick} role="button" tabIndex="0">
          <MenuIcon />
          <LocalMenu
            anchorEl={this.state.anchorEl}
            handleClose={this.handleMenuClose}
            items={this.createMenuItems()}
          />
        </MiniMenu>
      </React.Fragment>
    );
  }
}

export default Nav;
